import themeVars, { hexToRGB } from '../theme-vars'
import topbarMobileBG from '../../assets/svg/topbar_mobile_bg_esports.svg'

const headerStyles = {
  '*.offCanvasWrapper': {
    background: `linear-gradient(to bottom, ${themeVars.colors.purple}, ${hexToRGB(themeVars.colors.orange, 0.8)})`,
  },
  '.slideButtons': {
    display: 'flex',
    gridGap: '10px',
    justifyContent: 'space-around',
},
  '@media (min-width: 1024px)': {
      '.slideButtons': {
        display: 'flex',
        gridGap: '10px',
        justifyContent: 'flex-start',
    },
  },
  '*.headerContainer': { // Added * to make selector key different from residential, so it adds it instead of replacing it, which loses all our base styles
    'header.header': {
      '.sliderContainer': {
        '.slick-slider': {
          '.slick-dots': {
            'li': {
              'button::before': {
                border: `3px solid ${themeVars.colors.orange}`,
              },
              '&.slick-active': {
                'button::before': {
                  backgroundColor: themeVars.colors.orange,
                }
              }
            }
          }
        },
      },
      '.topBarMobile': { // MOBILE HEADER
        '&::before': {
          backgroundImage: `linear-gradient(135deg, ${hexToRGB(themeVars.colors.purple, 0.96)}, ${hexToRGB(themeVars.colors.orange, 0.96)})`,
        }
      },
      '.topBar': { // DESKTOP HEADER
        'nav.navSuper': {
          'ul.sisterSites': {
            li: {
              a: {
                color: themeVars.colors.slate,
                fontWeight: 200,
                opacity: 0.8,
                transition: 'opacity 0.2s ease-in-out',
                '&:hover': {
                  opacity: 1
                },
                '&.active': {
                  backgroundColor: themeVars.colors.orange,
                }
              },
            }
          },
        },
        'nav.navPrimary': {
          gridTemplateColumns: '405px 1fr',
          '.logo': {
            maxWidth: '400px',
          },
          'ul.mainMenu': {
            li: {
              'div.chakra-menu__menu-list': { // SUBMENU DROPDOWN
                button: {
                  '&:hover, &:focus': {
                    backgroundColor: themeVars.colors.orange,
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  'nav.breadcrumb': {
    display: 'none',
    'ol.breadcrumb__list': {
      listStyleType: 'none',
      display: 'flex',
      'li.breadcrumb__list__item': {
        marginLeft: '10px',
        marginRight: '10px',
        'a.breadcrumb__link': {
          color: themeVars.colors.purple,
          opacity: 0.8,
          transition: 'opacity 0.2s ease-in-out',
          '&:hover': {
            opacity: 1
          }
        },
        '&:first-of-type': {
          marginLeft: 0,
        }
      }
    }
  }
}

export default headerStyles