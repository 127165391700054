import React from "react"
import { graphql } from "gatsby"
import PagePost from '../../components/TemplateParts/PagePost'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import pageTheme from '../../gatsby-plugin-chakra-ui/theme'
import pageThemeBusiness from '../../gatsby-plugin-chakra-ui/theme-business'
import pageThemeEsports from '../../gatsby-plugin-chakra-ui/theme-esports'
import pageThemeWholesale from '../../gatsby-plugin-chakra-ui/theme-wholesale'
import pageThemeEnterprise from '../../gatsby-plugin-chakra-ui/theme-enterprise'
import pageThemeShortheader from '../../gatsby-plugin-chakra-ui/theme-shortheader'
import pageThemeAnnual from '../../gatsby-plugin-chakra-ui/theme-annual'
import SEO from '../../components/SEO'


const themePicker = (templateName) => {
  switch (templateName) {
    case 'Business': 
      return extendTheme({ ...pageThemeBusiness })
    case 'Esports': 
      return extendTheme({ ...pageThemeEsports })
    case 'Wholesale': 
      return extendTheme({ ...pageThemeWholesale })
    case 'Enterprise': 
      return extendTheme({ ...pageThemeEnterprise })
    case 'Short Header': 
      return extendTheme({ ...pageThemeShortheader })
    case 'Annual Report': 
      return extendTheme({ ...pageThemeAnnual })
    default:
      return extendTheme({ ...pageTheme })
  }
}

const Page = ({ data, pageContext, location }) => {
  const currTheme = themePicker(data.page.template.templateName)
  return (
    <ChakraProvider theme={currTheme}>
      <SEO
        title={!!data.page?.seoOptions?.seoTitle ? data.page.seoOptions.seoTitle : data.page.title}
        description={data.page.seoOptions.seoExcerpt}
        noIndex={data.page.seoOptions.seoNoIndex}
        image={!!data.page.featuredImage?.node?.localFile?.publicURL ? data.page.featuredImage.node.localFile.publicURL : null}
        imagew={!!data.page.featuredImage?.node?.mediaDetails?.width ? data.page.featuredImage.node.mediaDetails.width : null}
        imageh={!!data.page.featuredImage?.node?.mediaDetails?.height ? data.page.featuredImage.node.mediaDetails.height : null}

      />
        <PagePost data={data} pageContext={pageContext} />
    </ChakraProvider>
  )
}

export const query = graphql`
  query page($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      seoOptions {
        seoExcerpt
        seoTitle
        seoNoIndex
      }
      template {
        templateName
      }
      blocksJSON
      heroContent {
        heroSlides {
          heroSlideContentH1
          heroSlideContentH4
          heroSlideLink {
            url
            title
            target
          }
          heroSlideIllustrationSelect
          heroSlideEffect
          secondaryButton {
            target
            title
            url
          }
          tertiaryButton {
            target
            title
            url
          }
          heroSlideImage {
            altText
            localFile {
              url
              ext
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, quality: 96, placeholder: NONE)
              }
            }
          }
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            ext
            publicURL
            ...HeroImage
          }
          mediaDetails {
            width
            height
          }
        }
      }
    }
    images: allWpMediaItem(limit: 5000) {
      nodes {
        altText
        databaseId
        sourceUrl
        srcSet
        sizes
        title
      }
    }
  }
`

export default Page